.w-30 {
	width: 30%;
}

.certificate th {
	border: 1px solid black;
	padding: 3px 4px;
}

.certificate td {
	border: 0.5px solid black;
	font-size: 12px;
	padding: 1px 4px;
}

.br {
	height: 10px;
	display: block;
	content: "";
}

hr.line {
	height: 1px;
	width: 100%;
	background-color: #d60f0f;
	border: none;
}

@media print {
	.page-break {
		page-break-before: always;
	}
}

.commentD {
	color: #d60f0f;
	font-style: italic;
}